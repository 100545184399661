// @flow
import { Box, Typography, Link } from '@material-ui/core';
import React from 'react';
import Layout from '../../components/Layout/Layout';
import ContactForm from '../../components/Merchant/ContactForm/ContactForm';
import ApplySection from '../../components/Merchant/ApplySection';
import { contactUsStyles } from '../../styles/pages/merchant.styles';
import { SITE_METADATA } from '../../constants';
import ForwardOutboundLink from '../../components/shared/ForwardOutboundLink';

const ContactUsPage = () => {
  const classes = contactUsStyles({});

  return (
    <Layout
      isMerchant
      SEOProps={{
        title: SITE_METADATA.merchantContact.title,
        description: SITE_METADATA.merchantContact.description,
      }}
    >
      <Box className={classes.container}>
        <Typography variant="h1">ご相談のお申し込み</Typography>
      </Box>
      <section>
        <ContactForm
          description="必要事項をご記入のうえ、送信してください。３営業日以内に担当者からご連絡いたします。"
          details={
            <Typography>
              ※こちらはペイディとご契約の加盟店様用お問い合わせフォームです。
              <br />
              ペイディご利用者様は
              <Link
                component={ForwardOutboundLink}
                href="https://cs-support.paidy.com/support/tickets/new"
                target="_blank"
                rel="noopener noreferrer"
                underline="always"
              >
                『お問い合わせフォーム（ペイディご利用者様）』
              </Link>
              からお問い合わせください
            </Typography>
          }
          button="送信"
          page="merchant"
        />
      </section>
      <ApplySection />
    </Layout>
  );
};

// $FlowFixMe
export default ContactUsPage;
